<template>
  <div>
    <CRow>
      <CCol md="12" lg="12">
        <CCard>
          <CCardHeader>
            <h3 class="card-title ">{{ $t("staffListPage.userSearchContents.contentTitle") }}</h3>
          </CCardHeader>
          <CCardBody>
            <!-- <p>宿を検索して、次の操作を実施します。</p>
            <div class="bd-example mb-5">
              <li class="h6">宿の情報を編集すること</li>
              <li class="h6">宿のオペレーターの指定すること</li>
              <li class="h6">宿のステータス・ダッシュボードを確認すること</li>
            </div> -->
            <h4 class="sub-title">{{ $t("staffListPage.userSearchContents.userSearch") }}</h4>
            <p>{{ $t("staffListPage.userSearchContents.contentGuide") }}</p>
           <CForm>
            <CRow class="mb-2">
              <CCol lg="8">
                <CInput
                  :label="$t('staffListPage.userSearchContents.userEmail')"
                  class="search-input"
                  :placeholder="$t('staffListPage.userSearchContents.userEmailPlaceholder')"
                  horizontal
                  type="email"
                  v-model="query.email"
                  @keydown.enter="onEnterKeyPress($event,'email')"
                />
              </CCol>
              <CCol lg="2">
              <CButton
                  @click="onSearchClicked('email')"
                  class="info-button"
                  color="info"
              >
                <i class="icon cil-search"></i> {{ $t("staffListPage.userSearchContents.searchButton") }}
              </CButton>
                <!-- 検索 -->
              </CCol>
            </CRow>
            <CRow class="mb-2">
              <CCol lg="8">
                <CInput
                  :label="$t('staffListPage.userSearchContents.userName')"
                  class="search-input"
                  :placeholder="$t('staffListPage.userSearchContents.userNamePlaceholder')"
                  horizontal
                  type="text"
                  v-model="query.userName"
                  @keypress.enter="onEnterKeyPress($event,'userName')"
              />
              </CCol>
              <CCol lg="2">
                <CButton
                 @click="onSearchClicked('userName')"
                 class="info-button"
                 color="info"
                >
                <i class="icon cil-search"></i> {{ $t("staffListPage.userSearchContents.searchButton") }}
                </CButton>
                <!-- 検索 -->
              </CCol>
            </CRow>
            <transition name="fade">
              <CRow v-if="searchUsersNotFound">
                <CCol lg="6">
                  <CAlert color="danger">{{ $t("staffListPage.userSearchContents.userNotFoundAlert") }}</CAlert>
                </CCol>
              </CRow>
            </transition>
          </CForm>
              <div class="user-info-table">
                <dl class="row">
                  <dt class="col-sm-12">
                    <h4 class="sub-title">{{ $t("staffListPage.userSearchContents.userSearchList") }}</h4>
                    {{ $t("staffListPage.userSearchContents.totalNumber") }}: {{userCount}} | {{ $t("staffListPage.userSearchContents.resultNumber") }}: {{searchUsers.length}}
                  </dt>
                </dl>

                <CRow class="mb-2">
                  <CCol sm="3" class="paginationInput">
                    <CSelect
                      :label="$t('staffListPage.userSearchContents.pagenationLabel')"
                      placeholder=""
                      horizontal
                      type="number"
                      :options="searchUserPagenationCounts"
                      :value.sync="searchUserPagenationSelected"
                    />
                  </CCol>
                </CRow>
                <CDataTable
                  class="mb-0 table-outline"
                  hover
                  size="sm"
                  :items="searchUsers"
                  :fields="searchUserTableFields"
                  head-color="light"
                  table-filter
                  column-filter
                  sorter
                  itemps-per-page-select
                  :items-per-page="searchUserPagenationSelected"
                  pagination
                  v-if="!userLoading"
                  :tableFilter='{ placeholder: $t("tableFilter.placeholder"), label: $t("tableFilter.label") }'
                >
                  <!-- sorter
                :sorterValue.sync="tableSorter" -->
                  <td slot="userName" slot-scope="{ item }">
                    <strong>{{ item.userName }}</strong>
                  </td>
                  <td slot="fullName" slot-scope="{ item }">
                    <strong>{{ item.fullName }}</strong>
                  </td>
                  <td slot="mobile" slot-scope="{ item }">
                    <strong>{{ item.mobile }}</strong>
                  </td>
                  <td slot="email" slot-scope="{ item }">
                    <strong>{{ item.email }}</strong>
                  </td>
                  <!-- // TODO:検索したときに判定してスタッフの人はスタッフ権限付与ボタンを制御すること -->
                  <template #facility_user_relation_create="{item}">
                    <td class="py-2 text-center">
                      <CButton
                        color="info"
                        square
                        block
                        size="sm"
                        @click="onStaffSaveClicked(item)"
                        >{{ $t("staffListPage.userSearchContents.searchUsersList.tableBody.staffRegist") }}</CButton
                      >
                    </td>
                  </template>
                </CDataTable>
              </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <CRow>
      <CCol md="12" lg="12">
        <CCard>
          <CCardHeader>
            <h3 class="card-title ">{{ $t("staffListPage.staffListContents.contentTitle") }}</h3>
          </CCardHeader>
          <CCardBody>
            <!-- <p>宿を検索して、次の操作を実施します。</p>
            <div class="bd-example mb-5">
              <li class="h6">宿の情報を編集すること</li>
              <li class="h6">宿のオペレーターの指定すること</li>
              <li class="h6">宿のステータス・ダッシュボードを確認すること</li>
            </div> -->
              <div class="user-info-table">
                <dl class="row">
                  <dt class="col-sm-12">
                    <h4 class="sub-title">{{ $t("staffListPage.staffListContents.staffList") }}</h4>
                    {{ $t("staffListPage.staffListContents.totalNumber") }}: {{users.length}} 
                  </dt>
                </dl>
                <transition name="fade">
                  <CRow v-if="staffssNotFound">
                    <CCol lg="6">
                      <CAlert color="danger">{{ $t("staffListPage.staffListContents.staffNotFoundAlert") }}</CAlert>
                    </CCol>
                  </CRow>
                </transition>
                <CRow class="mb-2">
                  <CCol sm="3" class="paginationInput">
                    <CSelect
                      :label="$t('staffListPage.staffListContents.pagenationLabel')"
                      placeholder=""
                      horizontal
                      type="number"
                      :options="userPagenationCounts"
                      :value.sync="userPagenationSelected"
                    />
                  </CCol>
                </CRow>
                <CDataTable
                  class="mb-0 table-outline"
                  hover
                  size="sm"
                  :items="users"
                  :fields="userTableFields"
                  head-color="light"
                  table-filter
                  column-filter
                  sorter
                  itemps-per-page-select
                  :items-per-page="userPagenationSelected"
                  pagination
                  v-if="!userLoading"
                  :tableFilter='{ placeholder: $t("tableFilter.placeholder"), label: $t("tableFilter.label") }'
                >
                  <!-- sorter
                :sorterValue.sync="tableSorter" -->

                  <td slot="userName" slot-scope="{ item }">
                    <strong>{{ item.userName }}</strong>
                  </td>
                  <td slot="fullName" slot-scope="{ item }">
                    <strong>{{ item.fullName }}</strong>
                  </td>
                  <td slot="mobile" slot-scope="{ item }">
                    <strong>{{ item.mobile }}</strong>
                  </td>
                  <td slot="email" slot-scope="{ item }">
                    <strong>{{ item.email }}</strong>
                  </td>
                  
                  <template #facility_user_relation_create="{item}">
                    <td class="py-2 text-center">
                      <CButton
                        color="info"
                        square
                        block
                        size="sm"
                        @click="onStaffDeleteClicked(item)"
                        >
                        {{ $t("staffListPage.staffListContents.staffsList.tableBody.staffDelete") }}</CButton
                      >
                    </td>
                  </template>
                </CDataTable>
              </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- start_staffSaveConfirmModal -->
    <CModal
      color="info"
      :closeOnBackdrop="false"
      :centered="true"
      :show.sync="staffSaveConfirmModal"
    >
      <template #header>
        <h5>{{ $t("staffListPage.staffSaveConfirmModal.modalTitle") }}</h5>
      </template>
      <CRow class="mb-2">
        <CCol lg="12">
          <p>{{ $t("staffListPage.staffSaveConfirmModal.modalContent.modalGuide") }}</p>
        </CCol>
      </CRow>
      <CRow class="mb-2">
        <CCol lg="4">
          <p>{{ $t("staffListPage.staffSaveConfirmModal.modalContent.userName") }}:</p>
        </CCol>
        <CCol lg="8">
          <p v-if="!requestUser.userName">{{ $t("staffListPage.staffSaveConfirmModal.modalContent.notSet") }}</p>
          <p else>{{requestUser.userName}}</p>
        </CCol>
      </CRow>
      <CRow class="mb-2">
        <CCol lg="4">
          <p>{{ $t("staffListPage.staffSaveConfirmModal.modalContent.name") }}:</p>
        </CCol>
        <CCol lg="8">
          <p>{{requestUser.familyName}} {{requestUser.givenName}}</p>
        </CCol>
      </CRow>
      <CRow class="mb-2">
        <CCol lg="4">
          <p>{{ $t("staffListPage.staffSaveConfirmModal.modalContent.email") }}:</p>
        </CCol>
        <CCol lg="8">
          <p>{{requestUser.email}}</p>
        </CCol>
      </CRow>
      <CRow class="mb-2">
        <CCol lg="4">
          <p>{{ $t("staffListPage.staffSaveConfirmModal.modalContent.registStatus") }}:</p>
        </CCol>
        <CCol lg="6">
          <p v-if="requestUser.staff === true">
            {{ $t("staffListPage.staffSaveConfirmModal.modalContent.regist") }}
          </p>
          <p v-if="requestUser.staff === false">
            {{ $t("staffListPage.staffSaveConfirmModal.modalContent.notRegist") }}
          </p>
        </CCol>
      </CRow>
      <template #footer>
        <CButton
          @click="staffSaveConfirmModal = false"
          color="secondary"
          class="float-left"
          >
          <i class="icon cil-x"></i>
          {{ $t("staffListPage.staffSaveConfirmModal.cancelButton") }}
          </CButton>
          <!-- TODO:既にスタッフの場合は登録ボタンを押せないようにする -->
        <CButton
          color="success"
          @click="staffRegist()"
          :disabled="requestUser.staff"
          >
          <i class="icon cil-save"></i>
          {{ $t("staffListPage.staffSaveConfirmModal.relationSaveButton") }}
        </CButton>
      </template>
    </CModal>
    <!-- end_staffSaveConfirmModal -->

     <!-- start_staffSaveCompleteModal -->
    <CModal
      :show.sync="staffSaveCompleteModal"
      color="success"
      closeOnBackdrop
      :centered="true"
    >
      <template #header>
      <h5>{{ $t("staffListPage.staffSaveCompleteModal.modalTitle") }}</h5>
      </template>

      <p>{{ $t("staffListPage.staffSaveCompleteModal.modalContent.completeResult") }}</p>
      
      <template #footer>
        <CButton @click="staffSaveCompleteModal = false" color="secondary"
          >{{ $t("staffListPage.staffSaveCompleteModal.closeButton") }}</CButton
        >
      </template>
    </CModal>
    <!-- end_staffSaveCompleteModal -->

    <!-- start_staffDeleteConfirmModal -->
    <CModal
      color="warning"
      :closeOnBackdrop="false"
      :centered="true"
      :show.sync="staffDeleteConfirmModal"
    >
      <template #header>
        <h5>{{ $t("staffListPage.staffDeleteConfirmModal.modalTitle") }}</h5>
      </template>

      <CRow class="mb-2">
        <CCol lg="12">
          <p>{{ $t("staffListPage.staffDeleteConfirmModal.modalContent.modalGuide") }}</p>
        </CCol>
      </CRow>
      <CRow class="mb-2">
        <CCol lg="4">
          <p>{{ $t("staffListPage.staffDeleteConfirmModal.modalContent.userName") }}:</p>
        </CCol>
        <CCol lg="8">
          <p>{{requestUser.userName}}</p>
        </CCol>
      </CRow>
      <CRow class="mb-2">
        <CCol lg="4">
          <p>{{ $t("staffListPage.staffDeleteConfirmModal.modalContent.name") }}:</p>
        </CCol>
        <CCol lg="8">
          <p>{{requestUser.familyName}} {{requestUser.givenName}}</p>
        </CCol>
      </CRow>
      <CRow class="mb-2">
        <CCol lg="4">
          <p>{{ $t("staffListPage.staffDeleteConfirmModal.modalContent.email") }}:</p>
        </CCol>
        <CCol lg="8">
          <p>{{requestUser.email}}</p>
        </CCol>
      </CRow>
      <template #footer>
        <CButton
          @click="staffDeleteConfirmModal = false"
          color="secondary"
          class="float-left"
          >
          <i class="icon cil-x"></i>
          {{ $t("staffListPage.staffDeleteConfirmModal.cancelButton") }}
        </CButton>
        <CButton
          color="danger"
          @click="staffDelete()"
          >
        <i class="icon cil-save"></i>
        {{ $t("staffListPage.staffDeleteConfirmModal.deleteButton") }}
        </CButton>
      </template>
    </CModal>
    <!-- end_staffDeleteConfirmModal -->

    <!-- start_staffDeleteCompleteModal -->
    <CModal
      :show.sync="staffDeleteCompleteModal"
      color="success"
      closeOnBackdrop
      :centered="true"
    >
      <template #header>
      <h5>{{ $t("staffListPage.staffDeleteCompleteModal.modalTitle") }}</h5>
      </template>

      <p>{{ $t("staffListPage.staffDeleteCompleteModal.modalContent.modalGuide") }}</p>
      
      <template #footer>
        <CButton @click="staffDeleteCompleteModal = false" color="secondary"
          >{{ $t("staffListPage.staffDeleteCompleteModal.closeButton") }}</CButton
        >
      </template>
    </CModal>
    <!-- end_staffDeleteCompleteModal -->

    <!-- start_staffSaveErrorModal -->
    <CModal
      :show.sync="staffSaveErrorModal"
      color="danger"
      closeOnBackdrop
      :centered="true"
    >
      <template #header>
        <h5>{{$t("staffListPage.staffSaveErrorModal.modalTitle")}}</h5>
      </template>

      <p>{{$t("staffListPage.staffSaveErrorModal.modalContent.errorResult")}}</p>
      <p>{{$t("staffListPage.staffSaveErrorModal.modalContent.errorSolution")}}</p>
      
      <template #footer>
        <CButton @click="staffSaveErrorModal = false" color="secondary"
          >{{$t("staffListPage.staffSaveErrorModal.closeButton")}}</CButton
        >
      </template>
    </CModal>
    <!-- end_staffSaveErrorModal -->

     <!-- start_staffDeleteErrorModal -->
    <CModal
      :show.sync="staffDeleteErrorModal"
      color="danger"
      closeOnBackdrop
      :centered="true"
    >
      <template #header>
        <h5>{{$t("staffListPage.staffDeleteErrorModal.modalTitle")}}</h5>
      </template>

      <p>{{$t("staffListPage.staffDeleteErrorModal.modalContent.errorResult")}}</p>
      <p>{{$t("staffListPage.staffDeleteErrorModal.modalContent.errorSolution")}}</p>
      
      <template #footer>
        <CButton @click="staffDeleteErrorModal = false" color="secondary"
          >{{$t("staffListPage.staffDeleteErrorModal.closeButton")}}</CButton
        >
      </template>
    </CModal>
    <!-- end_staffDeleteErrorModal -->

    
   
  </div>
</template>

<script>
import i18n from "../../../i18n"
import axios from "axios"
import config from "../../../config"

// 会員システムのurl
const LIBERTY_MEMBER_SERVER_URL = config.LIBERTY_MEMBER_SERVER_URL;
// const LIBERTY_MEMBER_SERVER_URL = "https://points.liberty-resort.net";

export default {
  name: "StaffList",
  data() {
    return {
      searchUsersNotFound:false,
      staffssNotFound:false,
      userLoading:false,
      userCount: 0,
      userPagenationCounts: [{value:5},{value:10},{value:20},{value:50}],
      userPagenationSelected:5,
      searchUserPagenationCounts: [{value:5},{value:10},{value:20},{value:50}],
      searchUserPagenationSelected:5,
      staffSaveConfirmModal:false,
      staffSaveCompleteModal:false,
      staffDeleteConfirmModal:false,
      staffDeleteCompleteModal:false,
      staffSaveErrorModal:false,
      staffDeleteErrorModal:false,

      query:{
        email:"",
        userName:""
      },
      
      // ユーザー検索結果
      searchUsers:[],

      // スタッフとして登録されたユーザー
      users:[],

      // リクエストするユーザーデータ
      requestUser: {
        id:"",
        userName:"",
        firstName: "",
        lastName: "",
        familyName:"",
        givenName:"",
        email: "",
        telephone: "",
        description:"",
        status:null,
        staff:false,
        admin:false
      },

      // スタッフに登録するユーザーID
      registStaffUsersId:[],

      
      
      searchUserTableFields: [
        {
          key: "userName",
          label: `${i18n.t("staffListPage.userSearchContents.searchUsersList.tableHeader.userName")}`,
          sorter: true,
          filter: true,
          _classes: "text-center bg-light"
        },
        { 
          key: "fullName",
          label: `${i18n.t("staffListPage.userSearchContents.searchUsersList.tableHeader.name")}`,
          sorter: true,
          filter: true,
          _classes: "text-center bg-light"
        },
        { 
          key: "mobile",
          label: `${i18n.t("staffListPage.userSearchContents.searchUsersList.tableHeader.telephone")}`,
          _classes: "text-center bg-light"
        },
        { 
          key: "email",
          label: `${i18n.t("staffListPage.userSearchContents.searchUsersList.tableHeader.email")}`,
          _classes: "text-center bg-light"
        },
        {
          key: "facility_user_relation_create",
          label: `${i18n.t("staffListPage.userSearchContents.searchUsersList.tableHeader.staffRegist")}`,
          sorter: false,
          filter: false,
          _classes: "text-center bg-light",
        }
      ],

      userTableFields: [
        {
          key: "userName",
          label: `${i18n.t("staffListPage.staffListContents.staffsList.tableHeader.userName")}`,
          sorter: true,
          filter: true,
          _classes: "text-center bg-light"
        },
        { 
          key: "fullName",
          label: `${i18n.t("staffListPage.userSearchContents.searchUsersList.tableHeader.name")}`,
          sorter: true,
          filter: true,
          _classes: "text-center bg-light"
        },
        { 
          key: "mobile",
          label: `${i18n.t("staffListPage.staffListContents.staffsList.tableHeader.telephone")}`,
          _classes: "text-center bg-light"
        },
        { 
          key: "email",
          label: `${i18n.t("staffListPage.staffListContents.staffsList.tableHeader.email")}`,
          _classes: "text-center bg-light"
        },
        {
          key: "facility_user_relation_create",
          label: `${i18n.t("staffListPage.staffListContents.staffsList.tableHeader.staffDelete")}`,
          sorter: false,
          filter: false,
          _classes: "text-center bg-light",
        }
      ],
    };
  },
  computed: {},
  methods: {

    onEnterKeyPress: function (event, value) {
      // 日本語入力中のEnterキー操作は無効
      if (event.keyCode !== 13) return;
      switch (value) {
        case "email":
          if (!this.query.email) return;
          break;
        case "userName":
          if (!this.query.userName) return;
          break;
        default:
          return;
      }
      this.onSearchClicked(value);
    },


    // 検索ボタン押下
    onSearchClicked(value){
      var urlRequest;

      this.searchUsersNotFound = false;

      switch (value) {
        case "email":
          var email = encodeURIComponent(this.query.email);
          urlRequest = `${LIBERTY_MEMBER_SERVER_URL}/rest/users?email=${email}`;
          this.getUsers(urlRequest,value);
          break;
        
        case "userName":
          urlRequest = `${LIBERTY_MEMBER_SERVER_URL}/rest/users/${this.query.userName}`;
          this.getUsers(urlRequest,value);
          break;
        default:
          return;
      }
    },


    // テーブルボディを作成
    createUserTableBodies(users,key){

      switch (key) {
        case "user":
          // 検索内容リセット
          // これを消すと連続で検索ボタン押下時にテーブルにデータが追加されてしまう
          this.searchUsers = [];
          users.forEach(user => {
            // 各ユーザーをオブジェクトとして作成
            // saveUser()する際にid,statusが必要の為、ここで用意
            // CoreUIのテーブルがフラットな構造のデータ出ないとソート機能が使えない為、オブジェクトを作っている
            var userObj = {
              id : user.id,
              userName:user.name,
              lastName:user.lastName,
              firstName:user.firstName,
              familyName:user.familyName,
              givenName:user.givenName,
              fullName:`${user.familyName} ${user.givenName}(${user.lastName} ${user.firstName})`,
              address1 : user.address.address1,
              mobile : user.mobile,
              email : user.email,
              status : user.status,
            }
            this.searchUsers.push(userObj);
          });
          // console.log(this.searchUsers);
        break;

      case "staff":
        // 検索内容リセット
          // これを消すと連続で検索ボタン押下時にテーブルにデータが追加されてしまう
          this.users = [];

          users.forEach(user => {
            // 各ユーザーをオブジェクトとして作成
            // saveUser()する際にid,statusが必要の為、ここで用意
            // CoreUIのテーブルがフラットな構造のデータ出ないとソート機能が使えない為、オブジェクトを作っている
            var userObj = {
              id : user.id,
              userName:user.userName,
              lastName:user.lastName,
              firstName:user.firstName,
              familyName:user.familyName,
              givenName:user.givenName,
              fullName:`${user.familyName} ${user.givenName}(${user.lastName} ${user.firstName})`,
              // address1 : user.address.address1,
              mobile : user.telphone,
              email : user.email,
              status : user.status,
              staff : user.staff
            }
            this.users.push(userObj);
          });
          // console.log(this.users);
      }
    },


    // ユーザー検索（検索候補が複数ある場合）
    getUsers(urlRequest,value){
      axios
        .get(urlRequest, {
          headers: { Authorization: `Bearer ${this.$keycloak.token}` },
        })
        .then((resp) => {
          if(value == "userName"){
            // usernameの検索の場合ユーザーは１人だけの為、データをリストにする
            console.log(resp.data)

            const users = [];
            users.push(resp.data);
            // レスポンスデータからテーブルボディ情報作成
            this.createUserTableBodies(users,"user")
          }else{
            // username以外の検索は複数であるためそのまま使用する
            //  console.log(resp.data)
            // レスポンスデータからテーブルボディ情報作成
            this.createUserTableBodies(resp.data,"user")
          }

          // 検索件数が0件の時
          if(this.searchUsers.length == 0){
            this.searchUsersNotFound = true;
          }
        })
        .catch((err) => {
          console.log(err);
          this.searchUsersNotFound = true;
        });

    },

    // スタッフとして登録された情報を取得
    getStaffs(){
      var urlRequest = `/rest/user/?staff=true`;
      axios
        .get(urlRequest, {
          headers: { Authorization: `Bearer ${this.$keycloak.token}` },
        })
        .then((resp) => {
          
          // console.log(resp.data)
          // console.log(resp.data.users)
          const users = resp.data.users;

          // レスポンスデータからテーブルボディ情報作成
          this.createUserTableBodies(users,"staff")

          // 検索件数が0件の時
          if(this.users.length == 0){
            this.usersNotFound = true;
          }


          // // 検索したユーザーで宿とリレーションがあるか判別
          // // this.getRelation(this.users);
        })
        .catch((err) => {
          console.log(err);
          this.usersNotFound = true;
        });

    },

  

    // onFacilityEditPage(uniqueId) {
    //   this.$router.push({
    //     name: "宿編集",
    //     params: { uniqueId: uniqueId },
    //   });
    // },


    // 全ユーザー件数取得
    getUserCount(){

      //Login as registered user
      var urlRequest = `${LIBERTY_MEMBER_SERVER_URL}/rest/users`;
      axios
        .get(urlRequest, {
          headers: { Authorization: `Bearer ${this.$keycloak.token}` },
        })
        .then((resp) => {

          // console.log(resp.data);
          this.userCount = resp.data;

        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 登録ボタン押下
    onStaffSaveClicked(user){
      // console.log(user);
      this.selectUser(user);


    },


    createRequestUser(user,isStaff){

      // 宿探しに登録されている場合はstaffの値だけrespから使い、
      // 他の情報は会員システム側のデータを使う
      this.requestUser.id = user.id;
      this.requestUser.userName = user.userName;
      this.requestUser.lastName = user.lastName;
      this.requestUser.firstName = user.firstName;
      this.requestUser.familyName = user.familyName;
      this.requestUser.givenName = user.givenName;
      this.requestUser.email = user.email;
      this.requestUser.telephone = user.mobile;
      this.requestUser.description = "";
      this.requestUser.staff = isStaff;
      this.requestUser.admin = false;
      // console.log("createRequestUser")
      // console.log(this.requestUser)
    },

    // 選択したユーザーを宿探しから取得
    selectUser(user){

      // 初期化
      this.registStaffUsersId =[];

      var urlRequest = `/rest/user/${user.id}`;
      // var urlRequest = `http://localhost:8080/rest/user/${this.requestUser.id}`;
      axios
        .get(
            urlRequest, 
            {headers: { Authorization: `Bearer ${this.$keycloak.token}` },}
        )
        .then((resp) => {
          // 宿探しに登録されている場合はstaffの値だけrespから使い、他の情報は会員システム側のデータを使う
          // console.log(resp.data);
          // console.log(user);

          // リクエスト用兼ダイアログで表示する為のデータ作成
          const requestUser = resp.data;
          this.createRequestUser(user,requestUser.staff);
         
          // ダイアログに表示したユーザのidをスタッフ登録する値に保管
          this.registStaffUsersId.push(this.requestUser.id)

          // console.log(this.requestUser)
          
          this.showStaffSaveConfirmModal();

        })
        .catch((err) => {
          // 404でユーザーが見つからない時
          // この内容の中にある情報をダイアログで表示するためにデータ格納
          if (err.response.status === 404) {
            // リクエスト用兼ダイアログで表示する為のデータ作成
            // 宿探しに登録されていないので、スタッフではないためfalse
            this.createRequestUser(user,false);

            // ダイアログに表示したユーザのidをスタッフ登録する値に保管
            this.registStaffUsersId.push(this.requestUser.id)

            console.log(this.requestUser)

            this.showStaffSaveConfirmModal();

          }else{
            console.log(err);
            this.staffSaveErrorModal = true;
          }
        });
    },

    // 登録確認モーダル表示
    showStaffSaveConfirmModal(){
      this.staffSaveConfirmModal = true;
    },

    

    // 登録確認モーダルの登録ボタン押下
    // スタッフ登録実行
    staffRegist() {
      
      this.staffSaveConfirmModal = false;

      // 登録するユーザー情報のstaffパラメーターを切り替える
      this.requestUser.staff = true;

      var urlRequest = `/rest/user/staff`;
      axios
        .post(
            urlRequest, 
            this.requestUser,
            {headers: { Authorization: `Bearer ${this.$keycloak.token}` },}
        )
        .then((resp) => {
          console.log(resp.data);

          this.staffSaveCompleteModal = true;

          this.getStaffs();
          
          // console.log("スタッフ登録完了");
        })
        .catch((err) => {
          console.log(err);
          this.staffSaveErrorModal = true;
        });
    },


    // 削除ボタン押下
    onStaffDeleteClicked(user){

      //削除するユーザー情報作成
      this.createRequestUser(user,user.staff);

      // スタッフ削除確認ダイアログ
      this.showStaffDeleteConfirmModal();
    },

    // 削除確認モーダル表示
    showStaffDeleteConfirmModal(){
      // console.log("delete")
      this.staffDeleteConfirmModal = true;
    },



     // 削除確認モーダルの削除ボタン押下
    staffDelete(){
      
      this.staffDeleteConfirmModal = false;

      var urlRequest = `/rest/user/staff/${this.requestUser.id}`;
      axios
        .delete(
            urlRequest, 
            {headers: { Authorization: `Bearer ${this.$keycloak.token}` },}
        )
        .then((resp) => {
          console.log(resp.data);

          this.staffDeleteCompleteModal = true;

          this.getStaffs();
          
          // console.log("スタッフ削除完了");
        })
        .catch((err) => {
          console.log(err);
          this.staffDeleteErrorModal = true;
        });

    },

    

     

  },
  beforeMount() {
    this.getStaffs();
    this.getUserCount();
    
  },
};
</script>
